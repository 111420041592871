<template>
   <button class="btn google-login-btn"
       @click="$emit('click')">
    <img src="@/assets/images/google_icon.svg" />
    <span>CONTINUE WITH GOOGLE</span>
  </button>
</template>

<script>
  export default {
    name: 'GoogleLoginBtn'
  }
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/variables.scss';

  .google-login-btn {
    border: 1px solid #4285F4;
    background-color: #4285F4;
    color: $color-white;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: left;
    column-gap: calc(28% - 52px);
    margin-top: 10px;

    img {
      background: $color-white;
      box-sizing: border-box;
      width: 52px;
      height: 52px;
      padding: 9px;
      border-radius: 2px;
    }
  }
</style>