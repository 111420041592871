import Loader from '@/components/Loader'
import { isEmpty } from 'lodash'
import { Auth as AmplifyAuth } from 'aws-amplify'
import GoogleLoginBtn from '@/components/GoogleLoginBtn'

export default {
  components: {
    Loader,
    GoogleLoginBtn
  },
  data() {
    return {
      errorMessage: '',
      email: '',
      password: '',
      showPassword: false,
      loading: false
    }
  },
  computed: {
    error () {
      return !isEmpty(this.errorMessage)
    }
  },
  created () {
    this.redirectToIndexIfSignedIn()
  },
  methods: {
    redirectToIndexIfSignedIn() {
      if (this.$store.getters['auth/signedIn']) {
        this.$router.push({ name: 'index' })
      }
    },
    clearFields() {
      this.email = ''
      this.password = ''
    },
    clearErrors() {
      this.errorMessage = ''
    },
    async signUp() {
      this.clearErrors()
      this.manageLoading(true)

      try {
        await this.$store.dispatch('auth/signUp', {
          email: this.email,
          password: this.password
        })

        await this.signIn()
      }
      catch (error) {
        this.handleError(error)
      }
    },
    async signIn() {
      this.clearErrors()
      this.manageLoading(true)

      try {
        await AmplifyAuth.signOut()
        await this.$store.dispatch('auth/signIn', {
          email: this.email,
          password: this.password
        })

        this.$store.dispatch('modal/hideModal')
        this.clearFields()
        const redirect = this.$store.getters['globalData/getRedirectTo']
        if (!redirect) {
          return setTimeout(() => {
            ['login', 'signup', 'reset_password-token'].includes(this.$route.name)
              ? this.$router.push({ name: 'index' })
              : this.$router.go()
          }, 1000)
        }

        if(!!this.$store.getters['order/getOrderId']) {
          this.$store.dispatch('order/initOrder')
        }
        this.$store.dispatch('globalData/clearRedirectTo')
        this.$router.push({
          name: redirect.name,
          params: redirect.params,
          query: redirect.query
        })
      }
      catch (error) {
        this.handleError(error)
      }

      this.manageLoading(false)
    },
    async singInWithFacebook() {
      await this.signInWithExternalProvider('Facebook')
    },
    async singInWithGoogle() {
      await this.signInWithExternalProvider('Google')
    },
    async signInWithExternalProvider(provider) {
      sessionStorage.setItem('preLoginUrl', this.$route.fullPath)
      await AmplifyAuth.signOut()
      await AmplifyAuth.federatedSignIn({ provider })
    },
    handleError({ message }) {
      this.manageLoading(false)
      this.errorMessage = message
    },
    manageLoading (value) {
      this.loading = value
    }
  }
}